import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/modals/auth/auth.service';
import { map } from 'rxjs';

export const canActivateAuth: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isAnonymous$.pipe(
    map((isAnonymous) => (!isAnonymous ? true : router.createUrlTree([`/${router.url}`, 'login']))),
  );
};

export const canActivateAlreadyLogin: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.isLoggedIn$.pipe(map((isLoggedIn) => (isLoggedIn ? router.createUrlTree(['/']) : true)));
};

export const canActivateLogout: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  authService.logout();

  return router.createUrlTree(['/']);
};
